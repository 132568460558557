<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useActionParams } from '../../composables/actionParams';
import type { Action } from '~/types/action';

interface Estimation {
  periodConsumptionCompare: number | null;
  periodConsumptionCostCompare: number | null;
  sign: number | null;
}

// Stores
const actions = actionStore();
const { showToast } = toastStore();

// Composables
const { references, calculateImpactTimeSlots, calculateImpactValue } = useActionParams();
const { $posthog } = useNuxtApp();
const { t } = useI18n();

// Props and emits
const modal = defineModel<boolean>();
const props = defineProps<{
  action: Action;
}>();

// Data
const estimation = ref<Estimation | null>(null);

// Form
const dateRules = ref({
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
});
const schema = yup.object().shape({
  period_start: yup.date().required(t('auth.form.field_required')),
  period_end: yup.date().required(t('auth.form.field_required')),
  reference: yup.string().required(t('auth.form.field_required')),
  value: yup.number().when('reference', {
    is: 'value',
    then: () => yup.string().required(t('auth.form.field_required')),
  }),
  time_slots: yup.array(yup.array(yup.date().required(t('auth.form.field_required')))),
});
const { handleSubmit, values, resetField } = useForm<Action>({
  validationSchema: schema,
});

// Methods
const { execute: simulate, loading: simulating } = useCustomAsyncData({
  promise: handleSubmit(async () => {
    if (values.reference === 'time_slot') {
      const compare = await calculateImpactTimeSlots({
        action: {
          ...props.action,
          ...values,
        },
      });
      estimation.value = compare;
      $posthog.capture('action_estimated', {
        name: props.action.name,
        area_id: props.action.area_id,
        owner_id: props.action.owner_id,
        priority: props.action.priority,
      });
    } else {
      const compare = await calculateImpactValue({
        action: {
          ...props.action,
          ...values,
        },
      });
      estimation.value = compare;
      $posthog.capture('action_estimated', {
        name: values.name,
        area_id: values.area_id,
        owner_id: values.owner_id,
        priority: values.priority,
      });
    }
  }),
  options: { immediate: false },
});

const { execute: save, loading: saving } = useCustomAsyncData({
  promise: async () => {
    if (!estimation.value || !estimation.value?.periodConsumptionCompare || !estimation.value?.periodConsumptionCostCompare) return;

    await actions.updateAction(props.action.id, {
      ...values,
      week_impact_consumption: estimation.value.sign === 1 ? estimation.value.periodConsumptionCompare : 0,
      week_impact_cost: estimation.value.sign === 1 ? estimation.value.periodConsumptionCostCompare : 0,
      status: 'close',
    });

    modal.value = false;
    showToast({ type: 'success', title: t('global.success'), message: t('action_plan.form.action_edited') });
  },
  options: { immediate: false },
});

const { execute: closeWithoutEstimation, loading: closing } = useCustomAsyncData({
  promise: async () => {
    await actions.updateAction(props.action.id, {
      status: 'close',
    });

    modal.value = false;
    showToast({ type: 'success', title: t('global.success'), message: t('action_plan.form.action_edited') });
  },
  options: { immediate: false },
});

// Computed
const disabledPeriodEndDates = computed(() =>
  values.period_start ? [{ end: dayjs(values.period_start).subtract(1, 'day').toDate() }] : [],
);
const disabledTimeSlotEndDates = computed(() =>
  values.time_slots ? [{ end: dayjs(values.time_slots[0][0]).subtract(1, 'day').toDate() }] : [],
);

// Watch
watch([values], () => {
  if (estimation.value) estimation.value = null;
});
watch(
  () => values,
  () => {
    if (values.period_start && values.period_end && dayjs(values.period_start).isAfter(dayjs(values.period_end), 'hour')) {
      resetField('period_end');
    }
  },
  {
    deep: true,
  },
);
watch(
  () => values,
  () => {
    if (
      values.time_slots?.at(0)?.at(0) &&
      values.time_slots?.at(0)?.at(1) &&
      dayjs(values.time_slots[0][0]).isAfter(dayjs(values.time_slots[0][1]), 'hour')
    ) {
      resetField('time_slots.0.1');
    }
  },
  {
    deep: true,
  },
);
</script>

<template>
  <ui-modal v-model="modal" width="700" :title="$t('action_plan.evaluate_impact')">
    <div class="p-2">
      <!-- Récap -->
      <div class="grid grid-cols-4 text-start gap-y-4">
        <p class="text-gray-500 text-sm col-span-1">
          {{ $t('action_plan.action') }}
        </p>
        <p class="text-gray-900 col-span-3">
          {{ action.name }}
        </p>
        <p class="text-gray-500 text-sm col-span-1">
          {{ $t('action_plan.area') }}
        </p>
        <div class="col-span-3">
          <ui-badge color="gray">
            {{ action.area.name }}
          </ui-badge>
        </div>
      </div>

      <!-- Spacer -->
      <div class="w-full h-[1px] bg-gray-200 my-6" />

      <h3 class="text-gray-900 font-semibold text-base text-start mb-4">
        {{ $t('action_plan.test_setup') }}
      </h3>

      <!-- Period -->
      <div class="p-4 bg-gray-50 rounded-md mb-4">
        <p class="text-gray-500 text-sm text-start mb-2">
          {{ $t('action_plan.i_performed_my_action_of') }}
        </p>
        <div class="grid grid-cols-2 gap-4">
          <ui-form-input-date name="period_start" type="dateTime" :label="$t('global.start_date')" :rules="dateRules" hide-details />
          <ui-form-input-date
            name="period_end"
            type="dateTime"
            :label="$t('global.end_date')"
            :rules="dateRules"
            :disabled-dates="disabledPeriodEndDates"
            hide-details
          />
        </div>
      </div>

      <!-- Reference -->
      <div class="p-4 bg-gray-50 rounded-md mb-4">
        <p class="text-gray-500 text-sm text-start mb-2">
          {{ $t('action_plan.i_would_like_to_compare_with') }}
        </p>
        <div class="grid grid-cols-1 gap-4">
          <ui-form-input-select
            name="reference"
            :items="references"
            :label="$t('action_plan.value_of_reference')"
            :placeholder="$t('action_plan.reference')"
            hide-details
          />
        </div>
      </div>

      <!-- Time slot -->
      <div v-if="values.reference === 'time_slot'" class="p-4 bg-gray-50 rounded-md">
        <p class="text-gray-500 text-sm text-start mb-2">
          {{ $t('action_plan.choose_the_period_to_compare') }}
        </p>
        <div class="grid grid-cols-2 gap-4">
          <ui-form-input-date name="time_slots[0][0]" type="dateTime" :label="$t('global.start_date')" :rules="dateRules" hide-details />
          <ui-form-input-date
            name="time_slots[0][1]"
            type="dateTime"
            :label="$t('global.end_date')"
            :rules="dateRules"
            :disabled-dates="disabledTimeSlotEndDates"
            hide-details
          />
        </div>
      </div>

      <!-- Compare to value -->
      <div v-if="values.reference === 'value'" class="p-4 bg-gray-50 rounded-md">
        <p class="text-gray-500 text-sm text-start mb-2">
          {{ $t('action_plan.choose_the_value_to_compare') }}
        </p>
        <div class="grid grid-cols-1 gap-4">
          <ui-form-input-text name="value" hide-details type="number" right-text="kWh" />
        </div>
      </div>
    </div>

    <!-- Estimation Card -->
    <div v-if="simulating" class="w-full h-[150px] flex items-center justify-center">
      <ui-loader class="!w-8" />
    </div>
    <div
      v-if="estimation"
      :class="estimation.sign === 1 ? 'bg-orange-50 border-orange-200' : 'bg-red-50 border-red-200'"
      class="w-full p-4 border rounded-md mt-4"
    >
      <h3 class="text-start font-semibold text-gray-900 mb-4">Impact calculé</h3>
      <table>
        <tbody>
          <tr>
            <td>
              <p class="text-gray-500 text-sm text-start">
                {{ estimation.sign === 1 ? $t('action_plan.on_test_period_saved') : $t('action_plan.on_test_period_over_consumed') }}:
              </p>
            </td>
            <td class="pl-4">
              <div class="col-span-1 flex items-center justify-center">
                <ui-icon
                  name="Zap"
                  :stroke-width="1"
                  :class="estimation.sign === 1 ? 'stroke-amber-400' : 'stroke-red-400'"
                  class="w-5 mr-2"
                />
                <p class="mr-2 text-gray-900 font-semibold">
                  {{ Math.abs(estimation.periodConsumptionCompare ?? 0) }}
                </p>
                <p class="text-gray-500 text-sm">Kwh</p>
              </div>
            </td>
            <td class="px-6">
              <div class="h-[24px] bg-gray-300 w-[1px]" />
            </td>
            <td>
              <div class="col-span-1 flex items-center justify-center">
                <ui-icon
                  name="Banknote"
                  :stroke-width="1"
                  :class="estimation.sign === 1 ? 'stroke-amber-400' : 'stroke-red-400'"
                  class="w-5 mr-2"
                />
                <p class="mr-2 text-gray-900 font-semibold">
                  {{ Math.abs(estimation.periodConsumptionCostCompare ?? 0) }}
                </p>
                <p class="text-gray-500 text-sm">€</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <template #footer>
      <ui-button color="secondary" :loading="closing" :disabled="simulating || saving" @click="closeWithoutEstimation">
        {{ $t('action_plan.close_without_evaluate') }}
      </ui-button>
      <ui-button v-if="!estimation" color="primary" :disabled="closing" :loading="simulating" @click="simulate()">
        {{ $t('action_plan.evaluate') }}
      </ui-button>
      <ui-button v-else color="primary" :disabled="closing" :loading="saving" @click="save()">
        {{ $t('global.save') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
