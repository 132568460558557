<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import type { Action } from '~/types/action';
import { useI18n } from 'vue-i18n';
import { cleanJsonBeforeSend } from '~/helpers/data';
import type { Area } from '~/types/area';
import type { User } from '~/types/user';

interface Form extends Action {
  other_action: boolean;
}

// Composables
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { $posthog } = useNuxtApp();
const { priority } = useConstants();

// Stores
const { getUsers } = userStore();
const { showToast } = toastStore();
const actions = actionStore();

// Props & emits
const modal = defineModel<boolean>();
const props = defineProps<{
  action: Action | null;
}>();

// Data
const submiting = ref(false);

// Form
const schema = yup.object({
  name: yup.string().required(t('action_plan.form.action_name_is_required')),
  area_id: yup.number().required(t('action_plan.form.area_is_required')),
  owner_id: yup.string(),
  priority: yup.string().required(t('action_plan.form.priority_is_required')),
});
const { handleSubmit, setValues, resetForm } = useForm<Form>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    if (props.action) {
      await actions.updateAction(props.action.id, { ...values, status: 'open' });
      $posthog.capture('action_updated', {
        name: values.name,
        area_id: values.area_id,
        owner_id: values.owner_id,
        priority: values.priority,
      });
      showToast({ type: 'success', title: t('global.success'), message: t('action_plan.form.action_edited') });
    } else {
      const data = cleanJsonBeforeSend({ jsonObject: values, keys: ['other_action'] });
      await actions.insertActions(data);
      $posthog.capture('action_created', {
        name: values.name,
        area_id: values.area_id,
        owner_id: values.owner_id,
        priority: values.priority,
      });
      showToast({ type: 'success', title: t('global.success'), message: t('action_plan.form.action_created') });
    }
    if (values.other_action) {
      await resetForm();
    } else {
      router.replace({ query: undefined });
      modal.value = false;
    }
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  submiting.value = false;
});

// Computed
const title = computed(() => (props.action ? t('action_plan.edit_action') + `: ${props.action.name}` : t('action_plan.create_action')));
const areas = computed(() =>
  areaStore().getAreas.map((area: Area) => {
    return { key: area.id, label: area.name };
  }),
);
const users = computed(() =>
  getUsers.map((user: User) => {
    return { key: user.id, label: user.first_name + ' ' + user.last_name };
  }),
);

onMounted(() => {
  if (route.query.area_id) {
    setValues({ area_id: parseInt(route.query.area_id as string) });
  }
});

watch(
  () => modal,
  () => {
    if (props.action) {
      setValues({
        name: props.action.name,
        area_id: props.action.area_id,
        owner_id: props.action.owner_id,
        priority: props.action.priority,
        description: props.action.description,
        potential_saving: props.action.potential_saving,
      });
    }
  },
  {
    deep: true,
  },
);
</script>
<template>
  <ui-modal v-model="modal" width="700" :title="title">
    <div class="grid">
      <ui-form-input-text
        :label="$t('action_plan.action_name')"
        :placeholder="$t('action_plan.form.name_placeholder')"
        autocomplete="false"
        required
        name="name"
      />
      <ui-form-input-select
        name="area_id"
        :disabled="route.query.area_id ? true : false"
        :label="$t('action_plan.area')"
        :items="areas"
        required
      />
      <ui-form-input-select name="priority" :label="$t('action_plan.priority')" :items="priority" required />
      <ui-form-input-text
        :label="$t('action_plan.potential_saving')"
        placeholder="1000"
        autocomplete="false"
        type="number"
        right-icon="Euro"
        name="potential_saving"
      />
      <ui-form-input-select name="owner_id" :label="$t('action_plan.owner')" :items="users" />
      <ui-form-input-textarea name="description" :placeholder="$t('global.your_description')" :label="$t('global.description')" />
    </div>
    <template #footer>
      <ui-form-input-toggle
        v-if="!action"
        name="other_action"
        class="mr-4"
        :label="$t('action_plan.create_an_other_action')"
        hide-details
      />
      <ui-button :loading="submiting" @click="submit()">
        {{ action ? $t('action_plan.udpate_the_action') : $t('action_plan.create_the_action') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
