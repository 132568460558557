import { provideApolloClient } from '@vue/apollo-composable';
import { userStore } from '~/stores/user';
import { ACTION_MUTATION_DELETE, ACTION_MUTATION_INSERT, ACTION_MUTATION_UPDATE, ACTION_QUERY_LIST } from '~/graphql/action';
import { cleanJsonBeforeSend } from '~/helpers/data';
import type { Action, InsertAction, UpdateAction } from '~/types/action';
import type { Filter } from '~/components/ui/data/DataTable.vue';

interface State {
  actions: Action[];
  filterBy: Filter;
}

export const actionStore = defineStore({
  id: 'actionStore',
  state: (): State => ({
    actions: [],
    filterBy: {
      name: 'priority',
      type: 'asc',
    },
  }),
  getters: {
    getActions(): Action[] {
      return this.actions;
    },
    getFilter(): Filter {
      return this.filterBy;
    },
    getActionsFiltered(): Action[] {
      const data = this.actions;
      const priorityOrder = {
        no_priority: 0,
        low: 1,
        medium: 2,
        high: 3,
        urgent: 4,
      };

      if (this.filterBy?.name === 'priority') {
        if (this.filterBy?.type === 'asc') {
          return [...data].sort((a, b) => priorityOrder[b.priority] - priorityOrder[a.priority]);
        } else if (this.filterBy?.type === 'desc') {
          return [...data].sort((a, b) => priorityOrder[a.priority] - priorityOrder[b.priority]);
        }
      } else if (this.filterBy?.name === 'owner') {
        if (this.filterBy?.type === 'asc') {
          return [...data].sort((a, b) =>
            `${a.owner.first_name}-${a.owner.last_name}`.localeCompare(`${b.owner.first_name}-${b.owner.last_name}`),
          );
        } else if (this.filterBy?.type === 'desc') {
          return [...data].sort((a, b) =>
            `${b.owner.first_name}-${b.owner.last_name}`.localeCompare(`${a.owner.first_name}-${a.owner.last_name}`),
          );
        }
      }
      return data;
    },
    getActiveActions(): Action[] {
      const data = this.getActionsFiltered;
      return data.filter((action) => action.status === 'open');
    },
    getCloseActions(): Action[] {
      const data = this.getActionsFiltered;
      return data.filter((action) => action.status === 'close');
    },
    getArchivedActions(): Action[] {
      const data = this.getActionsFiltered;
      return data.filter((action) => action.status === 'archived');
    },
    getPotentialSaving(): number {
      return this.actions
        .filter((action) => action.potential_saving !== null)
        .filter((action) => action.status === 'open')
        .reduce((acc, action) => acc + (action.potential_saving ?? 0), 0);
    },
  },
  actions: {
    // Load actions from the API
    async loadActions(): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const actionsResponse = await apolloClient.query<{ actions: Action[] }>({
        query: ACTION_QUERY_LIST,
        variables: {
          site_id: globalStore().getSelectedSite,
        },
      });
      this.actions = actionsResponse.data.actions;
    },
    updateFilter(filter: Filter | null) {
      this.filterBy = filter;
    },
    async insertActions(action: InsertAction): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      const site_id = areaStore().getArea(action.area_id)?.site_id;
      if (!site_id) return;

      const populateAction: InsertAction = {
        ...action,
        site_id: site_id,
        status: 'open',
      };

      const { data } = await apolloClient.mutate<{ insert_actions_one: Action }>({
        mutation: ACTION_MUTATION_INSERT,
        variables: {
          object: populateAction,
        },
      });
      const insertedAction = data?.insert_actions_one;

      if (insertedAction && insertedAction.owner_id !== userStore().getCurrentUser.id) {
        notificationStore().createNotification({
          type: 'action-create',
          url: `/actions?actionId=${insertedAction.id}`,
          created_for: insertedAction.owner_id,
          data: {
            action_id: insertedAction.id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }

      await this.loadActions();
    },

    async updateAction(actionId: number, action: UpdateAction): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);

      const objectData = cleanJsonBeforeSend({
        jsonObject: action,
        keys: ['area', 'owner', 'incident', 'id'],
      });
      const { data } = await apolloClient.mutate<{ update_actions_by_pk: Action }>({
        mutation: ACTION_MUTATION_UPDATE,
        variables: {
          id: actionId,
          object: objectData,
        },
      });
      const updatedAction = data?.update_actions_by_pk;

      if (updatedAction && updatedAction.owner_id !== userStore().getCurrentUser.id) {
        notificationStore().createNotification({
          type: 'action-create',
          url: `/actions?actionId=${updatedAction.id}`,
          created_for: updatedAction.owner_id,
          data: {
            action_id: updatedAction.id,
          },
          created_by: userStore().getCurrentUser.id,
          read: false,
        });
      }

      await this.loadActions();
    },

    async deleteAction(actionId: number): Promise<void> {
      const { apolloClient } = useApollo();
      provideApolloClient(apolloClient);
      await apolloClient.mutate({
        mutation: ACTION_MUTATION_DELETE,
        variables: {
          id: actionId,
        },
      });

      await this.loadActions();
    },

    // ========== Incident
    getActiveActionsByIncident(incidentId: number): Action[] {
      return this.actions.filter(
        (action) =>
          globalStore().getSelectedSite.includes(action.site_id) && action.status === 'open' && action.incident?.id === incidentId,
      );
    },

    getCloseActionsByIncident(incidentId: number): Action[] {
      return this.actions.filter(
        (action) =>
          globalStore().getSelectedSite.includes(action.site_id) && action.status === 'close' && action.incident?.id === incidentId,
      );
    },
  },
});
